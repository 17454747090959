import React from "react";
import "./forRating.scss";

const ForRating = ({ initialValue = 0, size = 14, allowFraction = false }) => {
  const renderStars = () => {
    const fullStars = Math.floor(initialValue);
    const hasHalfStar = allowFraction && initialValue % 1 !== 0;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    return (
      <>
        {/* Render full stars */}
        {Array(fullStars)
          .fill(0)
          .map((_, index) => (
            <span
              key={`full-${index}`}
              className="star full"
              style={{ fontSize: `${size}px` }}
            >
              ★
            </span>
          ))}

        {/* Render half star */}
        {hasHalfStar && (
          <span className="star half" style={{ fontSize: `${size}px` }}>
            ★
          </span>
        )}

        {/* Render empty stars */}
        {Array(emptyStars)
          .fill(0)
          .map((_, index) => (
            <span
              key={`empty-${index}`}
              className="star empty"
              style={{ fontSize: `${size}px` }}
            >
              ☆
            </span>
          ))}
      </>
    );
  };

  return <div className="forRating">{renderStars()}</div>;
};

export default ForRating;
