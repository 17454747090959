"use client";
import { useState } from "react";
import { Input } from "@/components/ui/input";
import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import { country } from "../../../../constant/country";
import { PhoneNumberUtil } from "google-libphonenumber";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";
import { createPartnerLeadInZoho } from "@/action/zoho";

const Consultation = () => {
  const intl = useIntl();
  const phoneUtil = PhoneNumberUtil.getInstance();
  const treatments = useSelector((state) => state.treatments.treatments.data);
  const status = useSelector((state) => state.consultation.status);
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [selectedTreatment, setSelectedTreatment] = useState("");
  const [selectedCountry, setSelectedCountry] = useState({
    name: "India",
    flag: "🇮🇳",
    code: "IN",
    dial_code: "+91",
  });
  const [phoneNumber, setPhoneNumber] = useState("");

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = async () => {
    let consultationData = {
      name: name,
      country: selectedCountry.name,
      phone_number: phoneNumber,
    };

    if (selectedTreatment) {
      consultationData.treatment = selectedTreatment;
    }

    if (!isPhoneValid(selectedCountry.dial_code + phoneNumber)) {
      return toast.error(intl.formatMessage({ id: "invalidPhoneNumber" }));
    }

    await createPartnerLeadInZoho({
      ...consultationData,
      parter_type: ["Direct Patient"],
    });
  };

  return (
    <div className="servicesWrapper">
      <div className="border border-[#EEEEEE] rounded-[20px] shadow-[0_3px_10px_rgb(0,0,0,0.1)] w-full p-4">
        <p className="text-[32px] sm:text-[28px] xs:text-[24px] leading-14 tracking-wide text-center mt-4 text-[#006daf] font-bold">
          {intl.formatMessage({ id: "getConsultation" })}
        </p>
        <div className="flex flex-col sm:flex-row items-stretch sm:items-end gap-4 mx-2 sm:mx-5 mt-5">
          <div className="w-full sm:w-[240px]">
            <Label className="mb-2 text-gray-700 block">
              {intl.formatMessage({ id: "nameLabel" })}
            </Label>
            <Input
              className="w-full h-12 px-4 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder={intl.formatMessage({ id: "namePlaceholder" })}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="w-full sm:w-[240px]">
            <Label className="mb-2 text-gray-700 block">
              {intl.formatMessage({ id: "treatmentLabel" })}
            </Label>
            <Select onValueChange={(val) => setSelectedTreatment(val)}>
              <SelectTrigger className="w-full h-12 px-4 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500">
                <SelectValue
                  placeholder={intl.formatMessage({
                    id: "treatmentPlaceholder",
                  })}
                />
              </SelectTrigger>
              <SelectContent>
                {treatments?.map((treatment) => (
                  <SelectItem key={treatment.id} value={treatment.id}>
                    {treatment.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="w-full sm:w-[240px]">
            <Label className="mb-2 text-gray-700 block">
              {intl.formatMessage({ id: "countryLabel" })}
            </Label>
            <Select onValueChange={(val) => setSelectedCountry(val)}>
              <SelectTrigger className="w-full h-12 px-4 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500">
                <SelectValue
                  placeholder={intl.formatMessage({ id: "countryPlaceholder" })}
                />
              </SelectTrigger>
              <SelectContent>
                {country?.map((country) => (
                  <SelectItem key={country.name} value={country}>
                    {country.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="w-full sm:w-[240px]">
            <Label className="mb-2 text-gray-700 block">
              {intl.formatMessage({ id: "mobileLabel" })}
            </Label>
            <div className="flex w-full h-12 border border-gray-200 rounded-lg overflow-hidden focus-within:ring-2 focus-within:ring-blue-500">
              <div className="flex items-center gap-2 px-4 bg-white">
                <span className="text-sm">{selectedCountry.flag}</span>
                <span className="text-gray-600">
                  {selectedCountry.dial_code}
                </span>
              </div>
              <Input
                placeholder={intl.formatMessage({ id: "mobilePlaceholder" })}
                className="flex-1 h-full border-0 ring-0 focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0 shadow-none outline-none"
                value={phoneNumber}
                onChange={(e) => {
                  if (e.target.value.length > 10) return;
                  setPhoneNumber(e.target.value);
                }}
              />
            </div>
          </div>

          <Button
            className="w-full sm:w-auto h-12 px-8 "
            onClick={handleSubmit}
          >
            {intl.formatMessage({ id: "chatNowButton" })}
          </Button>
        </div>
      </div>
      <FullScreenLoader isLoading={status === "loading"} />
    </div>
  );
};

export default Consultation;
