'use client';
import { usePathname } from 'next/navigation';
import CustomCarousel from '@/components/CustomCarousel/CustomCarousel';
import './doctorsCarousel.scss';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import DoctorCardWithApi from './DoctorCard/DoctorCardWithApi';
import { useIntl } from 'react-intl';
import { fetchTopDoctors, selectAllTopDoctors } from '@/slice/topDoctorsSlice';

export default function DoctorsCarousel({ itemsPerPage, data }) {
  const [selectedCountry, setSelectedCountry] = useState('India');
  const pathname = usePathname();
  const isHomePage = pathname === '/';
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.locale);
  const doctors = useSelector(selectAllTopDoctors);
  const intl = useIntl();

  useEffect(() => {
    if (isHomePage) {
      dispatch(
        fetchTopDoctors({
          filters: {
            country: {
              $contains: selectedCountry,
            },
          },
          locale: lang,
          page: 1,
          pageSize: 10,
        })
      );
    }
  }, [selectedCountry, lang, dispatch, isHomePage]);

  const displayDoctors = isHomePage ? { data: doctors } : data;

  return (
    <div className="doctorsCarouselWrapper">
      <div className="doctorsContainer">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-8">
          <p className="doctorsHeading">
            {intl.formatMessage({ id: 'findThe' })}{' '}
            <span className="highlight">
              {intl.formatMessage({ id: 'topDoctors' })}
            </span>
          </p>
          <br />

        </div>
        {isHomePage && (
          <div className="mt-4 md:mt-0">
            <label className="text-gray-600 text-lg mr-4">
                Select Your Country for Doctors
            </label>
            <select
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
              className="px-4 py-2 rounded-lg border border-gray-200 bg-white text-gray-800 min-w-[200px] focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none cursor-pointer"
              style={{
                backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right 1rem center',
                backgroundSize: '1.5em 1.5em',
                paddingRight: '2.5rem',
              }}
            >
              <option value="India">India</option>
              <option value="Thailand">Thailand</option>
              <option value="Turkey">Turkey</option>
              <option value="USA">USA</option>
              <option value="UAE">UAE</option>
              <option value="South Korea">South Korea</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Germaney">Germaney</option>
              <option value="UK">UK</option>
            </select>
          </div>
        )}

        {displayDoctors?.data?.length > 0 && (
          <CustomCarousel itemsPerPage={itemsPerPage}>
            {displayDoctors.data.map((doctor, i) => (
              <DoctorCardWithApi
                key={doctor.id || i}
                doctor={doctor}
              />
            ))}
          </CustomCarousel>
        )}

        <Link href="/doctors" className="viewMoreBtn">
          {intl.formatMessage({ id: 'viewAllDoctors' })}
        </Link>
      </div>
    </div>
  );
}
