import * as React from "react";
import { Card, CardContent } from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import "./customCarousel.scss"; // Custom styles if needed

export default function CustomCarousel({ children, itemsPerPage }) {
  // Define the number of items per page based on screen size
  // const [itemsToShow, setItemsToShow] = React.useState(itemsPerPage);

  // // Effect to update itemsToShow based on screen width
  // React.useEffect(() => {
  //     const updateItemsToShow = () => {
  //         if (window.innerWidth <= 480) {
  //             setItemsToShow(1); // Show 1 item on mobile
  //         } else if (window.innerWidth <= 1024) {
  //             setItemsToShow(itemsPerPage - 2); // Show fewer items on tablet
  //         } else {
  //             setItemsToShow(itemsPerPage); // Default items for desktop
  //         }
  //     };

  //     window.addEventListener('resize', updateItemsToShow);
  //     updateItemsToShow(); // Call once to set initial value

  //     return () => {
  //         window.removeEventListener('resize', updateItemsToShow);
  //     };
  // }, [itemsPerPage]);

  return (
    <div className="shadcn-carousel-wrapper w-full max-w-full">
      <Carousel className="w-full">
        <CarouselContent>
          {React.Children.map(children, (child, index) => (
            <CarouselItem
              key={index}
              className={`basis-1/1 sm:basis-1/1 md:basis-1/${itemsPerPage} xl:basis-1/${itemsPerPage} 2xl:basis-1/${itemsPerPage}  lg:basis-1/${itemsPerPage}`}
            >
              {child}
            </CarouselItem>
          ))}
        </CarouselContent>

        {children?.length > 1 && (
          <>
            <CarouselPrevious />
            <CarouselNext />
          </>
        )}
      </Carousel>
    </div>
  );
}
