"use client";
import "./treatments.scss";
import Image from "next/image";
import { useEffect, useMemo } from "react";
import Link from "next/link";
import { routeName } from "@/utils/commonfuntions";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTreatments,
  selectAllTreatments,
  selectAllTreatmentsStaus,
} from "@/slice/treatmentsSlice";
import Head from "next/head";
import { useIntl } from "react-intl";
import FullScreenLoader from "@/app/components/FullScreenLoader/FullScreenLoader";

export default function Treatments({ numOfRows, data }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.locale);
  const treatmentData = useSelector(selectAllTreatments);
  const selectAllTreatmentsStausInfo = useSelector(selectAllTreatmentsStaus);

  const treatment = data || treatmentData;

  useEffect(() => {
    if (!data) dispatch(fetchTreatments(lang));
  }, [data, dispatch, lang]);

  // Memoize structured data for SEO
  const treatmentsStructuredData = useMemo(
    () =>
      treatment?.data?.map((t) => ({
        "@context": "https://schema.org",
        "@type": "MedicalProcedure",
        name: t?.name,
        image: t?.img?.url,
        description: t?.description,
      })),
    [treatment],
  );

  return (
    <>
      <Head>
        <script type="application/ld+json">
          {JSON.stringify(treatmentsStructuredData)}
        </script>
      </Head>
      <div className="treatmentsWrapper">
        <div className="treatmentsContent">
          <h1 className="m-0 p-0">
            <p className="tagline">
              {intl.formatMessage({ id: "exploreTreatments" })}
              <span className="greyTagline">
                {` ${intl.formatMessage({
                  id: "acrossSpecialities",
                })}`}
              </span>
            </p>
          </h1>

          <div className="treatmentsContainer">
            {treatment?.data?.map((treatment) => (
              <Link
                key={treatment?.id}
                href={{
                  pathname: `/specialities/${routeName(treatment?.slug)}`,
                }}
                className="hover:shadow-lg transition-all duration-300"
                style={{
                  textDecoration: "none",
                  borderRadius: "20px",
                }}
              >
                <div className="treatment">
                  <Image
                    loading="lazy"
                    className="w-auto h-[92px] mx-auto"
                    src={treatment?.img?.url}
                    alt={intl.formatMessage({
                      id: "treatmentImageAlt",
                    })}
                    width={80}
                    height={92}
                  />
                  <p className="treatmentName">{treatment.name}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <FullScreenLoader
          isLoading={selectAllTreatmentsStausInfo === "loading"}
        />
      </div>
    </>
  );
}
