'use client';
import { useEffect, useMemo, useState } from 'react';
import CustomCarousel from '@/components/CustomCarousel/CustomCarousel';
import './hospitalsCarousel.scss';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTopHospitals,
  selectAllTopHospitals,
} from '@/slice/topHospitalsSlice';
import HospitalCardWithApi from './HospitalCard/HospitalCardWithApi';
import { useIntl } from 'react-intl';

export default function HospitalCarousel({ itemsPerPage, data }) {
  const [selectedCountry, setSelectedCountry] = useState('India');
  const intl = useIntl();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.locale);
  const selectAllTopHospitalsData = useSelector(selectAllTopHospitals);

  const topHospitals = useMemo(
    () => selectAllTopHospitalsData || data ,
    [data, selectAllTopHospitalsData]
  );

  useEffect(() => {
    dispatch(
      fetchTopHospitals({
        ...(selectedCountry && {
          filters: {
            country: selectedCountry
          }
        }),
        locale: lang,
        pagination: {
          page: 1,
          pageSize: 10
        }
      })
    );
  }, [selectedCountry, lang, dispatch]);

  return (
    <div className="hospitalsCarouselWrapper">
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-8">
        <h1 className="p-0 m-0">
          <p className="hospitalsHeading">
            {intl.formatMessage({ id: 'exploreTopHospitals' })}{' '}
            <span className="bold">
              {intl.formatMessage({ id: 'topHospitals' })}
            </span>
          </p>
        </h1>
      </div>
      <div className="mt-4 md:mt-0">
        <label className="text-gray-600 text-lg mr-4">
                    Select Your Country for Hospitals
        </label>
        <select
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
          className="px-4 py-2 rounded-lg border border-gray-200 bg-white text-gray-800 min-w-[200px] focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none cursor-pointer"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 1rem center',
            backgroundSize: '1.5em 1.5em',
            paddingRight: '2.5rem',
          }}
        >
          <option value="India">India</option>
          <option value="Thailand">Thailand</option>
          <option value="Turkey">Turkey</option>
          <option value="USA">USA</option>
          <option value="UAE">UAE</option>
          <option value="South Korea">South Korea</option>
          <option value="Malaysia">Malaysia</option>
          <option value="Germaney">Germaney</option>
          <option value="UK">UK</option>
        </select>
      </div>

      {topHospitals?.data?.length > 0 && (
        <CustomCarousel itemsPerPage={itemsPerPage}>
          {topHospitals.data.map((hospital) => (
            <HospitalCardWithApi
              key={hospital.id}
              hospital={hospital}
            />
          ))}
        </CustomCarousel>
      )}

      <Link href="/hospitals" className="viewMoreBtn">
        {intl.formatMessage({ id: 'viewAllHospitals' })}
      </Link>
    </div>
  );
}
