import React from "react";
import "./doctorCard.scss";
import Image from "next/image";
import Link from "next/link";
import Head from "next/head";
import { useIntl } from "react-intl";
import ForRating from "@/components/Rating/ForRating";

export default function DoctorCardWithApi({ doctor }) {
  const intl = useIntl();

  const doctorData = {
    "@context": "https://schema.org",
    "@type": "Person",
    name: doctor?.name,
    jobTitle: doctor?.Designation,
    alumniOf: doctor?.institute,
    description: doctor?.descrption,
    image: doctor?.profile?.url,
    gender: doctor?.gender,
    department: doctor?.Department,
    location: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressCountry: doctor?.country,
        addressLocality: doctor?.city,
      },
    },
    worksFor: doctor?.Visiting_consultant_at,
  };

  return (
    <Link href={`/doctors/${doctor?.slug}`}>
      <div className="doctorCardWrapper hover:shadow-lg transition-all duration-300 w-[300px] h-[460px] flex flex-col">
        <Head>
          <script type="application/ld+json">
            {JSON.stringify(doctorData)}
          </script>
        </Head>
        <div className="doctorImgWrapper w-full h-[120px] relative">
          <Image
            loading="lazy"
            src={doctor?.profile?.url || "/images/default-doctor.png"}
            alt={doctor?.name || "Doctor Profile"}
            fill
            sizes="300px"
            className="object-cover rounded-full"
          />
        </div>
        <div className="flex-1 w-full px-4 overflow-y-auto">
          <span className="flex justify-center mb-2 w-full">
            <ForRating
              initialValue={doctor?.rating || 4.6}
              allowFraction
              size={14}
              readonly
              className="flex flex-row"
            />
          </span>
          <div className="doctorInfo text-center w-full">
            <p className="name text-lg font-medium mb-1 line-clamp-1">
              {doctor?.name || "\u00A0"}
            </p>
            {doctor?.institute && (
              <p className="studiedAt text-sm mb-2 line-clamp-1">
                {doctor?.institute}
              </p>
            )}
            {doctor?.descrption && (
              <p className="description text-sm text-gray-600 mb-2 line-clamp-2">
                {doctor?.descrption}
              </p>
            )}
            {doctor?.experiance && (
              <p className="heading mb-1 line-clamp-1">
                EXPERIENCE IN YEAR:{" "}
                <span className="subHeading">{doctor?.experiance} Years</span>
              </p>
            )}
            {doctor?.surgeries && (
              <p className="heading mb-1 line-clamp-1">
                SURGERIES :{" "}
                <span className="subHeading1">{doctor?.surgeries}+</span>
              </p>
            )}
            {doctor?.gender && (
              <p className="gender mb-1 line-clamp-1">
                Gender: {doctor?.gender}
              </p>
            )}
            {(doctor?.city || doctor?.country) && (
              <p className="location mb-1 line-clamp-1">
                Location:{" "}
                {[doctor?.city, doctor?.country].filter(Boolean).join(", ")}
              </p>
            )}
          </div>
        </div>

        <div className="priceInfo w-full px-4 pb-4 mt-auto">
          <p className="priceHeading text-center mb-2 line-clamp-1">
            {intl.formatMessage({
              id: "treatment_price_on_request",
            })}
          </p>
          <div className="freeConsultBtn text-center line-clamp-1 w-full">
            {intl.formatMessage({ id: "free_text_consult" })}
          </div>
        </div>
      </div>
    </Link>
  );
}
